import AppRouter from '@components/app.router'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { useLogin } from '@hooks/use-login.hook'
import { selectIsLoggedIn } from '@store/user/user.slice'
import { useEffect } from 'react'

function App(): JSX.Element {
  const { refreshTokens, getUserInfo } = useLogin()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  useEffect(() => {
    if (isLoggedIn) {
      getUserInfo()
      return
    }

    const tokens = localStorage.getItem('trokens')
    if (tokens) {
      refreshTokens()
    }
  }, [isLoggedIn])

  return <AppRouter />
}

export default App
