import facebookIcon from '@assets/icons/facebook.svg'
import footerLogo from '@assets/icons/logo-small.png'
import twitterIcon from '@assets/icons/twitter.svg'
import { Link } from 'react-router-dom'

import styles from './footer.module.scss'

interface IFooterProperties {}

export default function Footer(props: IFooterProperties): JSX.Element {
  return (
    <footer className={styles.footer}>
      <span className={styles.homeIndicator}></span>
      <img className={styles.logo} src={footerLogo} alt="Tradasy" />

      <div className={styles.copyright}>© Tradasy. All rights reserved 2022.</div>

      <div className={styles.social}>
        <Link to={'/'} target={'_blank'}>
          <img src={twitterIcon} alt="Twitter" />
        </Link>
        <Link to={'/'} target={'_blank'}>
          <img src={facebookIcon} alt="Facebook" />
        </Link>
      </div>
    </footer>
  )
}
