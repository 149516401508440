import { WebsocketContext } from '@api/websocket-provider'
import TokenChange from '@components/TokenChange/token-change.component'
import { useAppDispatch, useAppSelector } from '@hooks/use-app-dispatch.hook'
import type { ICurrency } from '@models/currency.interface'
import { GameStateEnum } from '@models/game.interface'
import { WsResponseAction } from '@models/ws.interface'
import {
  selectOpponentPick,
  selectPickedCurrencies,
  setOpponentPick,
  setPicked,
} from '@store/currency-pick/currency-pick.slice'
import { selectGame, selectOpponent } from '@store/game/game.slice'
import { selectUser } from '@store/user/user.slice'
import { calculateTotalChange } from '@utils/utils'
import clsx from 'clsx'
import _ from 'lodash'
import React, { useContext, useEffect } from 'react'

import ChosenToken from '../ChosenToken/chosen-token.component'
import PrizePool from '../PrizePool/prize-pool.component'
import styles from './drafts.module.scss'

export default function Drafts() {
  const picked = useAppSelector(selectPickedCurrencies)
  const opponentPick = useAppSelector(selectOpponentPick)
  const { id, username } = useAppSelector(selectUser)
  const { state, maxCryptos, cryptos } = useAppSelector(selectGame)
  const { socket, isReady } = useContext(WebsocketContext)
  const dispatch = useAppDispatch()
  const { username: oppUsername } = useAppSelector(selectOpponent)

  // Write tokens data when currency event is coming
  useEffect(() => {
    if (state === GameStateEnum.preparation) {
      return
    }
    if (!isReady) {
      return
    }

    const currencyListener = (data: any) => {
      if (id === null) {
        return
      }
      const myPick: ICurrency[] = []
      const myCryptosIndex = _.findIndex(cryptos, { user: { id } })
      const oppCryptosIndex = myCryptosIndex === 0 ? 1 : 0
      cryptos[myCryptosIndex]?.cryptos.forEach((tkn) => {
        const finded = data.find((f: ICurrency) => f.symbol === tkn.symbol)
        myPick.push(finded)
      })
      dispatch(setPicked(myPick))

      const opponentsPick: ICurrency[] = []
      cryptos[oppCryptosIndex]?.cryptos.forEach((tkn) => {
        const finded = data.find((f: ICurrency) => f.symbol === tkn.symbol)
        opponentsPick.push(finded)
      })
      dispatch(setOpponentPick(opponentsPick))
    }
    socket.on(WsResponseAction.gameCurrencies, currencyListener)

    return () => {
      socket.off(WsResponseAction.gameCurrencies, currencyListener)
    }
  }, [isReady, cryptos, dispatch, state, id])

  return (
    <div className={styles.drafts}>
      <div className={styles.yours}>
        <div className={styles.mobileRow}>
          <h2 className={styles.choiceTitle}>{username} portfolio</h2>
          <PrizePool />
        </div>
        {state === GameStateEnum.preparation ? (
          <div className={clsx(styles.tokensWrapper, styles.tokensWrapper_mt)}>
            {picked.map((item) => (
              <ChosenToken key={item.symbol} token={item} />
            ))}
            {Array.from({ length: maxCryptos - picked.length }).map((el, index) => (
              <ChosenToken key={`empty-${index}`} token={null} />
            ))}
          </div>
        ) : (
          <div className={styles.infoWrapper}>
            <div className={styles.tokensWrapper}>
              {picked.map((item) => (
                <ChosenToken key={item.symbol} token={item} />
              ))}
            </div>
            <div className={styles.totalWrapper}>
              Total:&nbsp;&nbsp;
              <TokenChange value={calculateTotalChange(picked)} />
            </div>
          </div>
        )}
      </div>

      <div className={styles.opponent}>
        <h2 className={styles.choiceTitle}>
          {oppUsername.length > 0 ? oppUsername : 'Opponent'} portfolio
        </h2>
        {state === GameStateEnum.preparation ? (
          <>
            <div className={clsx(styles.tokensWrapper, styles.tokensWrapper_mt)}>
              {Array.from({ length: maxCryptos }).map((el, index) => (
                <ChosenToken key={`empty-${index}`} token={null} hidden />
              ))}
            </div>
            <p>You may see this only after timer ending</p>
          </>
        ) : (
          <div className={styles.infoWrapper}>
            <div className={styles.tokensWrapper}>
              {opponentPick.map((item) => (
                <ChosenToken key={item.symbol} token={item} />
              ))}
            </div>
            <div className={styles.totalWrapper}>
              Total:&nbsp;&nbsp;
              <TokenChange value={calculateTotalChange(opponentPick)} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
