import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'

import type { IAccessTokens } from './response.interface'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

axiosClient.interceptors.request.use((config) => {
  // @ts-ignore
  const tokens: IAccessTokens = JSON.parse(localStorage.getItem('trokens'))
  if (tokens) {
    // @ts-ignore
    config.headers.Authorization = `Bearer ${tokens.accessToken}`
  }
  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    const res = error.response
    // if (res.status == 401) {
    //   window.location.href = '/'
    // }
    console.error(`Error. Status Code: ${res.status}`)
    return Promise.reject(res.data)
  }
)

// To share cookies to cross site domain, change to true.
axiosClient.defaults.withCredentials = false

export async function getRequest<T>(URL: string): Promise<AxiosResponse<T, any>> {
  return axiosClient.get<T>(`/${URL}`).then((response) => response)
}

export async function postRequest<T>(URL: string, payload: any, config?: AxiosRequestConfig) {
  return axiosClient.post<T>(`/${URL}`, payload, config).then((response) => response)
}

export async function putRequest<T>(URL: string, payload: any) {
  return axiosClient.put<T>(`/${URL}`, payload).then((response) => response)
}

export async function deleteRequest<T>(URL: string) {
  return axiosClient.delete<T>(`/${URL}`).then((response) => response)
}
