import { ReactComponent as DownIcon } from '@assets/icons/down-change.svg'
import { ReactComponent as UpIcon } from '@assets/icons/up-change.svg'
import { roundNumber } from '@utils/utils'
import clsx from 'clsx'
import React from 'react'

import styles from './token-change.module.scss'

interface ITokenChangeProperties {
  value: number | string
  showIcon?: boolean
}

export default function TokenChange({ value, showIcon = true }: ITokenChangeProperties) {
  return (
    <div className={clsx(styles.change, value < 0 && styles.change_negative)}>
      {showIcon && (value < 0 ? <DownIcon /> : <UpIcon />)}
      {`${value < 0 ? '' : '+'}${roundNumber(+value)}`}%
    </div>
  )
}
