export enum WsAction {
  startSearch = 'start_search',
  stopSearch = 'stop_search',
  chooseCryptos = 'choose_cryptos',
}

export enum WsResponseAction {
  currency = 'currency',
  gameReady = 'game_ready',
  gameStarted = 'game_started',
  gameEnds = 'game_ends',
  searchStopped = 'search_stopped',
  gameCanceled = 'game_canceled',
  allCurrencies = 'all_currencies',
  gameCurrencies = 'game_currencies',
}
