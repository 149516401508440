import Button from '@components/shared/button/button.component'
import ModalWrapper from '@components/shared/modal-wrapper/modal-wrapper.component'
import TokenChange from '@components/TokenChange/token-change.component'
import { useAppDispatch, useAppSelector } from '@hooks/use-app-dispatch.hook'
import {
  selectOpponentPick,
  selectPickedCurrencies,
} from '@store/currency-pick/currency-pick.slice'
import { selectIsWinner, selectOpponent } from '@store/game/game.slice'
import { selectUser } from '@store/user/user.slice'
import { calculateTotalChange } from '@utils/utils'
import clsx from 'clsx'
import React from 'react'

import ChosenToken from '../ChosenToken/chosen-token.component'
import styles from './result-modal.module.scss'

interface IResultModalProperties {
  isOpen: boolean
  onClose: () => void
}

export default function ResultModal(props: IResultModalProperties) {
  const { isOpen, onClose } = props
  const yourPicked = useAppSelector(selectPickedCurrencies)
  const opponentPick = useAppSelector(selectOpponentPick)
  const isWinner = useAppSelector(selectIsWinner)
  const dispatch = useAppDispatch()
  const { username } = useAppSelector(selectUser)
  const { username: oppUsername } = useAppSelector(selectOpponent)

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearPicked())
  //   }
  // }, [])

  return (
    <ModalWrapper
      isOpen={isOpen}
      toggle={onClose}
      styleVariant="secondary"
      className={styles.result}
    >
      {isWinner ? (
        <h4 className={styles.title}>Congratulations! You won!</h4>
      ) : (
        <h4 className={clsx(styles.title, styles.title_lose)}>Uh-oh! You lost!</h4>
      )}

      <div className={styles.picksWrapper}>
        <div className={styles.picks}>
          <p>{username}</p>
          <div className={styles.tokens}>
            {yourPicked.map((t) => (
              <ChosenToken key={t.symbol} token={t} />
            ))}
          </div>
          <div className={styles.total}>
            Total:&nbsp;
            <TokenChange value={calculateTotalChange(yourPicked)} />
          </div>
        </div>

        <span className={styles.picksDivider}></span>

        <div className={styles.picks}>
          <p>{oppUsername}</p>
          <div className={styles.tokens}>
            {opponentPick.map((t) => (
              <ChosenToken key={t.symbol} token={t} />
            ))}
          </div>
          <div className={styles.total}>
            Total:&nbsp;
            <TokenChange value={calculateTotalChange(opponentPick)} />
          </div>
        </div>
      </div>
      <Button additionalClassnames={styles.btn} onClick={onClose} variant="secondary">
        Continue
      </Button>
    </ModalWrapper>
  )
}
