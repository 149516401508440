import { useClaimTokens } from '@blockchain/game-contract-api'
import { amountToWei } from '@utils/utils'
import { useEffect, useState } from 'react'

import { useGetBalance } from './use-get-balance.hook'

export function useWithdrawHook(amount: string) {
  const [error, setError] = useState('')
  const [status, setStatus] = useState<'none' | 'loading' | 'failed' | 'success'>('none')

  const { state, send } = useClaimTokens()
  const { getBalance } = useGetBalance()

  const withdraw = async () => {
    setStatus('loading')
    await send(amountToWei(amount))
  }

  useEffect(() => {
    if (state.status === 'Exception' || state.status === 'Fail') {
      setStatus('failed')
      setError(state.errorMessage as string)
    }
    if (state.status === 'Success') {
      setStatus('success')
      getBalance()
    }
  }, [state])

  return { withdraw, error, status, setStatus }
}
