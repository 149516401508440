import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg'
// import { ReactComponent as ProfileIcon } from '@assets/icons/profile-icon.svg'
import profileIcon from '@assets/icons/profile-icon.png'
import { ReactComponent as WalletIcon } from '@assets/icons/wallet.svg'
import { ReactComponent as WithdrawIcon } from '@assets/icons/withdraw.svg'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { selectUser } from '@store/user/user.slice'
import React from 'react'

import styles from './profile-menu.module.scss'

interface IProfileMenuProperties {
  openDepositModal: () => void
  openWithdrawModal: () => void
  handleLogout: () => void
}

export default function ProfileMenu(props: IProfileMenuProperties) {
  const { openDepositModal, openWithdrawModal, handleLogout } = props
  const { username } = useAppSelector(selectUser)

  return (
    <div className={styles.menu}>
      <div className={styles.header}>
        <img src={profileIcon} alt="profile" />
        {username}
      </div>
      <div className={styles.list}>
        <p>Wallet</p>
        <button className={styles.btn} onClick={openDepositModal}>
          <WalletIcon /> Deposit
        </button>
        <button className={styles.btn} onClick={openWithdrawModal}>
          <WithdrawIcon /> Withdraw
        </button>
      </div>
      <div className={styles.list}>
        <p>Account</p>
        <button className={styles.btn} onClick={handleLogout}>
          <LogoutIcon /> Logout
        </button>
      </div>
    </div>
  )
}
