import { callGetUserBalance } from '@blockchain/game-contract-api'
import { setBalance } from '@store/user/user.slice'
import { useEthers } from '@usedapp/core'

import { useAppDispatch } from './use-app-dispatch.hook'

export function useGetBalance() {
  const { account, library } = useEthers()
  const dispatch = useAppDispatch()

  const getBalance = async () => {
    if (account) {
      const amount = await callGetUserBalance(account, library)
      dispatch(setBalance(+amount))
    }
  }

  return { getBalance }
}
