import { useApproveTokens, useDepositTokens } from '@blockchain/game-contract-api'
import { amountToWei } from '@utils/utils'
import { useEffect, useState } from 'react'

import { useGetBalance } from './use-get-balance.hook'

export function useDeposit(amount: string) {
  const [error, setError] = useState('')
  const [status, setStatus] = useState<'none' | 'loading' | 'failed' | 'success'>('none')

  const { state: approveState, send: approveTokens } = useApproveTokens()
  const { state: depositState, send: depositTokens } = useDepositTokens()
  const { getBalance } = useGetBalance()

  const handleApprove = async () => {
    setStatus('loading')
    await approveTokens(process.env.REACT_APP_GAME_CONTRACT_ADDRESS, amountToWei(amount))
  }

  const handleDeposit = async () => {
    await depositTokens(amountToWei(amount))
  }

  useEffect(() => {
    if (approveState.status === 'Exception' || approveState.status === 'Fail') {
      setStatus('failed')
      setError(approveState.errorMessage as string)
    }
    if (approveState.status === 'Success') {
      handleDeposit()
    }
  }, [approveState])

  useEffect(() => {
    if (depositState.status === 'Exception' || depositState.status === 'Fail') {
      setStatus('failed')
      setError(depositState.errorMessage as string)
    }
    if (depositState.status === 'Success') {
      setStatus('success')
      getBalance()
    }
  }, [depositState])

  return { error, handleApprove, status, setStatus }
}
