import { useContractFunction } from '@usedapp/core'
import { Contract, ethers } from 'ethers'

import {
  gameContractAbi,
  gameContractAddress,
  usdtContractAbi,
  usdtContractAddress,
} from './constants'

const nftContractInterface = new ethers.utils.Interface(gameContractAbi)
const nftContract = new Contract(gameContractAddress as string, nftContractInterface)

const usdtContractInterface = new ethers.utils.Interface(usdtContractAbi)
const usdtContract = new Contract(usdtContractAddress as string, usdtContractInterface)

export const callGetUserBalance = async (address: string, library: any) => {
  const contract = new Contract(gameContractAddress as string, gameContractAbi, library)
  const value = await contract.getUserBalance(address)
  return value ? ethers.utils.formatUnits(value) : 0
}

export function useDepositTokens() {
  const { state, send } = useContractFunction(nftContract, 'deposit')
  return { state, send }
}

export function useApproveTokens() {
  const { state, send } = useContractFunction(usdtContract, 'approve')
  return { state, send }
}

export function useClaimTokens() {
  const { state, send } = useContractFunction(nftContract, 'claim')
  return { state, send }
}
