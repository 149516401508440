import bigLogo from '@assets/icons/logo-vertical.png'
import chartsImg from '@assets/images/charts.png'
import Button from '@components/shared/button/button.component'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { useLogin } from '@hooks/use-login.hook'
import { selectIsLoggedIn } from '@store/user/user.slice'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './promo.module.scss'

export default function Promo() {
  const navigate = useNavigate()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const { executeLogin } = useLogin()

  const handleTryButton = () => {
    if (isLoggedIn) {
      navigate('/search-game')
    } else {
      executeLogin()
    }
  }

  return (
    <section className={`container ${styles.promo}`}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <img src={bigLogo} alt="Tradasy" />
          <h2 className="groovyTitle">Where fantasy sports meets financial trading</h2>
        </div>
        <img className={styles.chartsImg} src={chartsImg} alt="charts" />
      </div>

      <Button additionalClassnames={styles.tryBtn} onClick={handleTryButton}>
        Try for free
      </Button>
    </section>
  )
}
