import '@styles/global.scss'

import ErrorFallback from '@components/error-fallback/error-fallback.component'
import { setupStore } from '@store/store'
import type { Config } from '@usedapp/core'
import { BSC, BSCTestnet, DAppProvider } from '@usedapp/core'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import App from './app'

// @ts-ignore
const isMainnet = !!+process.env.REACT_APP_IS_MAINNET

const config: Config = {
  readOnlyChainId: isMainnet ? BSC.chainId : BSCTestnet.chainId,
  readOnlyUrls: {
    [BSC.chainId]: 'https://bsc-dataseed.binance.org',
    [BSCTestnet.chainId]: 'https://bsc-testnet.public.blastapi.io',
  },
  notifications: {
    expirationPeriod: 1000,
    checkInterval: 200_000,
  },
  refresh: 'never',
}

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement)

const store = setupStore()

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <DAppProvider config={config}>
      <Provider store={store}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <App />
        </ErrorBoundary>
      </Provider>
    </DAppProvider>
  </BrowserRouter>
  // </React.StrictMode>
)
