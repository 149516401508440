import advIcon1 from '@assets/icons/adv-1.svg'
import advIcon2 from '@assets/icons/adv-2.svg'
import advIcon3 from '@assets/icons/adv-3.svg'
import advIcon4 from '@assets/icons/adv-4.svg'
import advIcon5 from '@assets/icons/adv-5.svg'
import React from 'react'

import styles from './advantages.module.scss'

const advData = [
  {
    id: 1,
    icon: advIcon1,
    content: 'Opportunity to double your money in a short time period',
  },
  {
    id: 2,
    icon: advIcon2,
    content: 'Low cost, hands-on financial learning experience',
  },
  {
    id: 3,
    icon: advIcon3,
    content: 'Eliminate market risk by competing against an individual',
  },
  {
    id: 4,
    icon: advIcon4,
    content: 'Chance to still make money when the market tanks',
  },
  {
    id: 5,
    icon: advIcon5,
    content: 'A secure platform with low commission fees',
  },
]

export default function Advantages() {
  return (
    <section className={`container ${styles.adv}`}>
      <h3 className="groovyTitle groovyTitle_sub">Advantages of Tradasy</h3>
      <div className={styles.wrapper}>
        {advData.map((adv) => (
          <article className={styles.item} key={adv.id}>
            <img src={adv.icon} alt="Advantages" />
            <p>{adv.content}</p>
          </article>
        ))}
      </div>
    </section>
  )
}
