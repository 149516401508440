import type { DependencyList, EffectCallback } from 'react'
import { useEffect, useRef } from 'react'

export const useDidMountEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const initialRender = useRef(true)

  // @ts-ignore
  useEffect(() => {
    let effectReturns: void | (() => void | undefined) = () => {}

    if (initialRender.current) {
      initialRender.current = false
    } else {
      // @ts-ignore
      effectReturns = effect()
    }

    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns
    }
  }, deps)
}
