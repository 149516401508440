import Footer from '@components/shared/footer/footer.component'
import Header from '@components/shared/header/header.component'
import { Outlet, useLocation } from 'react-router-dom'

export interface ILayoutProperties {}

export default function MainLayout(): JSX.Element {
  const location = useLocation()

  return (
    <div className={location.pathname !== '/' ? 'global-bg' : ''}>
      <Header />

      <Outlet />

      <Footer />
    </div>
  )
}
