import type { ICurrency } from '@models/currency.interface'
import { ChainId } from '@usedapp/core'
import { BigNumber, ethers } from 'ethers'

// eslint-disable-next-line sonarjs/cognitive-complexity
export const checkAndChangeChainId = async () => {
  // @ts-ignore
  const isMainnet = !!+process.env.REACT_APP_IS_MAINNET

  const chain = Number.parseInt(window.ethereum.chainId, 16)

  if (!isMainnet) {
    if (chain !== ChainId.BSCTestnet) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: BigNumber.from(ChainId.BSCTestnet).toHexString() }],
        })
      } catch (error: any) {
        if (error.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: BigNumber.from(ChainId.BSCTestnet).toHexString(),
                chainName: 'BSC Testnet',
                rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'] /* ... */,
              },
            ],
          })
        }
        throw new Error('Did not change network')
      }
    }
  } else if (chain !== ChainId.BSC) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: BigNumber.from(ChainId.BSC).toHexString() }],
      })
    } catch (error: any) {
      if (error.code === 4902) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: BigNumber.from(ChainId.BSC).toHexString(),
              chainName: 'BSC Mainnet',
              rpcUrls: ['https://bsc-dataseed.binance.org'] /* ... */,
            },
          ],
        })
      }
      throw new Error('Did not change network')
    }
  }
}

export const roundNumber = (value: number) => {
  if (typeof value === 'number') {
    return value.toFixed(2)
  }
  return value
}

export const calculateTotalChange = (tokens: ICurrency[]) => {
  const res = tokens.reduce((previous, current) => previous + +current.change, 0)
  return roundNumber(res)
}

export const amountToWei = (value: string) => {
  return ethers.utils.parseUnits(value, 'ether')._hex
}

export const isInputDecimalValid = (value: string) => {
  const hasDecimal = /[,.]/g.test(value)
  if (hasDecimal) {
    const [, decimal] = value.split('.')
    if (decimal && decimal.length > 2) {
      return false
    }
  }
  return true
}
