import * as React from 'react'

import Advantages from './components/advantages/advantages.component'
import FAQ from './components/faq/faq.component'
import How from './components/how/how.components'
import Promo from './components/promo/promo.component'
import What from './components/what/what.component'
import styles from './home.module.scss'

export interface IMainHomeProperties {}

export default function Home() {
  return (
    <main className={styles.home}>
      <Promo />
      <What />
      <How />
      <Advantages />
      <FAQ />
    </main>
  )
}
