import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { selectGame } from '@store/game/game.slice'
import React from 'react'

import styles from './prize-pool.module.scss'

export default function PrizePool() {
  const { bet } = useAppSelector(selectGame)

  return (
    <h3 className={styles.prizePool}>
      Prize pool: <span>{bet * 2} USDT</span>
    </h3>
  )
}
