import howImage1 from '@assets/images/how-1.png'
import howImage2 from '@assets/images/how-2.png'
import howImage3 from '@assets/images/how-3.png'
import howImage4 from '@assets/images/how-4.png'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './how.module.scss'

export default function How() {
  return (
    <section className={`container ${styles.how}`}>
      <h3 className="groovyTitle groovyTitle_sub">How it works</h3>
      <div className={styles.cardsWrapper}>
        <img src={howImage1} alt="" />
        <img src={howImage2} alt="" />
        <img src={howImage3} alt="" />
        <img src={howImage4} alt="" />
      </div>
      <div className={styles.textWrapper}>
        <h4>Connect metamask</h4>
        <span className={styles.textDivider}></span>
        <h4>Deposit USDT</h4>
        <span className={styles.textDivider}></span>
        <div className={styles.textMultiple}>
          <h4>Search game</h4>
          <span></span>
          <h4>Pick cryptocurrencies</h4>
          <span></span>
          <h4>Win USDT</h4>
        </div>
        <span className={styles.textDivider}></span>
        <h4>Withdraw funds</h4>
      </div>

      <Swiper className={styles.mobileSlider} freeMode slidesPerView={'auto'} spaceBetween={10}>
        <SwiperSlide className={styles.mobileCard}>
          <img src={howImage1} alt="" />
          <h4>Connect metamask</h4>
        </SwiperSlide>
        <SwiperSlide className={styles.mobileCard}>
          <img src={howImage2} alt="" />
          <h4>Deposit USDT</h4>
        </SwiperSlide>
        <SwiperSlide className={styles.mobileCard}>
          <img src={howImage3} alt="" />
          <div className={styles.textMultiple}>
            <h4>Search game</h4>
            <span></span>
            <h4>Pick cryptocurrencies</h4>
            <span></span>
            <h4>Win USDT</h4>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.mobileCard}>
          <img src={howImage4} alt="" />
          <h4>Withdraw funds</h4>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}
