import type { IMeResponse } from '@api/response.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '@store/store'
import type { IUser } from '@store/user/user.model'

interface IUsersState {
  user: IUser
  isLoading: boolean
  error: string
  isLoggedIn: boolean
}

const initialState: IUsersState = {
  user: {
    wallet: '',
    username: '',
    balance: 0,
    id: null,
  },
  isLoading: false,
  error: '',
  isLoggedIn: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setWallet: (state, action: PayloadAction<string>) => {
      state.user.wallet = action.payload
    },
    setBalance: (state, action: PayloadAction<number>) => {
      state.user.balance = action.payload
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.user.username = action.payload
    },
    setLoggedIn: (state) => {
      state.isLoggedIn = true
    },
    setLoggedOut: (state) => {
      state.isLoggedIn = false
    },
    setUser: (state, action: PayloadAction<IMeResponse>) => {
      state.user = { ...state.user, ...action.payload }
    },
  },
  extraReducers: {},
})

export const { setWallet, setBalance, setLoggedIn, setLoggedOut, setUsername, setUser } =
  userSlice.actions

export const selectUser = (state: RootState) => state.userReducer.user

export const selectIsLoggedIn = (state: RootState) => state.userReducer.isLoggedIn

export default userSlice.reducer
