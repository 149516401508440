import gameService from '@api/services/game.service'
import { WebsocketContext } from '@api/websocket-provider'
import { useAppDispatch, useAppSelector } from '@hooks/use-app-dispatch.hook'
import { useDidMountEffect } from '@hooks/use-did-mount-effect.hook'
import type { ICurrency } from '@models/currency.interface'
import { GameStateEnum } from '@models/game.interface'
import { WsAction, WsResponseAction } from '@models/ws.interface'
import { pickCurrency, selectPickedCurrencies } from '@store/currency-pick/currency-pick.slice'
import { selectGame } from '@store/game/game.slice'
import Fuse from 'fuse.js'
import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import CurrencyItem from '../CurrencyItem/currency-item.component'
import styles from './currency-picker.module.scss'

export default function CurrencyPicker() {
  const picked = useAppSelector(selectPickedCurrencies)
  const dispatch = useAppDispatch()
  const { maxCryptos, state } = useAppSelector(selectGame)
  const [allCryptos, setAllCryptos] = useState<ICurrency[]>([])
  const [searched, setSearched] = useState<ICurrency[]>([])
  const { socket, isReady } = useContext(WebsocketContext)

  const fuse = new Fuse(allCryptos, {
    keys: ['name', 'symbol'],
    threshold: 0.2,
  })

  const handleSearch = (value: string) => {
    if (value === '') {
      setSearched(allCryptos)
      return
    }
    const searchResult = fuse.search(value)
    const res: ICurrency[] = []
    searchResult.forEach((el) => res.push(el.item))
    setSearched(res)
  }

  const selectCurrency = (currency: ICurrency) => {
    if (picked.length >= maxCryptos) {
      return
    }
    dispatch(pickCurrency(currency))
  }

  const updateSearchedList = useCallback(
    (newData: ICurrency[]) => {
      const updatedSearched: ICurrency[] = searched.map((token) =>
        _.find(newData, { symbol: token.symbol })
      ) as ICurrency[]
      setSearched(updatedSearched)
    },
    [searched]
  )

  // Get all currencies for pick on startup
  useEffect(() => {
    if (!isReady) {
      return
    }
    const getAllCurrencies = async () => {
      try {
        const resp = await gameService.getCryptosList()
        setAllCryptos(resp.data)
        setSearched(resp.data)
      } catch (error) {
        console.log(error)
      }
    }
    getAllCurrencies()
  }, [isReady])

  // Send picked tokens through socket
  useDidMountEffect(() => {
    if (!isReady && state !== GameStateEnum.preparation) {
      return
    }
    const pickedArray = picked.map((p) => p.symbol)
    socket.emit(WsAction.chooseCryptos, pickedArray)
  }, [picked, isReady])

  // Watch for all currencies update from websocket
  useEffect(() => {
    if (!isReady) {
      return
    }
    const allCurrenciesListener = (allCurrencies: ICurrency[]) => {
      setAllCryptos(allCurrencies)
      updateSearchedList(allCurrencies)
    }
    socket.on(WsResponseAction.allCurrencies, allCurrenciesListener)

    return () => {
      socket.off(WsResponseAction.allCurrencies, allCurrenciesListener)
    }
  }, [isReady, updateSearchedList])

  return (
    <div className={styles.main}>
      <div className={styles.searchWrapper}>
        <span>
          Pick currencies({picked.length}/{maxCryptos})
        </span>
        <input
          className={styles.searchInput}
          type="text"
          name="search"
          id="search"
          placeholder="Search..."
          onChange={({ target: { value } }) => handleSearch(value)}
        />
      </div>

      <div className={styles.pickWrapper}>
        {searched.map((token) => (
          <CurrencyItem key={token?.symbol} token={token} selectCurrency={selectCurrency} />
        ))}
      </div>
    </div>
  )
}
