import TokenChange from '@components/TokenChange/token-change.component'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import type { ICurrency } from '@models/currency.interface'
import { selectPickedByName } from '@store/currency-pick/currency-pick.slice'
import { roundNumber } from '@utils/utils'
import clsx from 'clsx'
import React from 'react'

import styles from './currency-item.module.scss'

interface ICurrencyItemProperties {
  token: ICurrency
  selectCurrency: (token: ICurrency) => void
}

export default function CurrencyItem(props: ICurrencyItemProperties) {
  const { token, selectCurrency } = props
  const isPicked = useAppSelector((state) => selectPickedByName(state, token.name))

  return (
    <article
      className={clsx(
        styles.item,
        token.change < 0 && styles.item_negative,
        isPicked && styles.item_disabled
      )}
      onClick={() => selectCurrency(token)}
    >
      <img
        className={styles.icon}
        src={`https://www.cryptocompare.com${token.image}`}
        alt={token.symbol}
      />
      <p className={styles.name}>{token.name}</p>
      <TokenChange value={roundNumber(+token.change)} />
      <div className={styles.currency}>{roundNumber(+token.currency)}$</div>
    </article>
  )
}
