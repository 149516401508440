import whatIsImg from '@assets/images/what-is.png'
import whatWeDoImg from '@assets/images/what-we-do.png'
import whatWeDoMobileImg from '@assets/images/what-we-do-mobile.png'
import React from 'react'

import styles from './what.module.scss'

export default function What() {
  return (
    <section className={`container ${styles.what}`}>
      <div className={styles.whatIs}>
        <img src={whatIsImg} alt="What is Tradasy" />
        <div>
          <h3 className="groovyTitle groovyTitle_sub">What is Tradasy?</h3>
          <img className={styles.whatIsMobileImg} src={whatIsImg} alt="What is Tradasy" />
          <p className={styles.text}>
            An exciting play-to-earn game, in which, players enter tournaments, where they
            pick cryptocurrencies for a time frame and compete with others.
          </p>
        </div>
      </div>

      <div className={styles.whatWeDo}>
        <div>
          <h3 className="groovyTitle groovyTitle_sub">What we do?</h3>
          <img
            className={styles.whatIsMobileImg}
            src={whatWeDoMobileImg}
            alt="What we do?"
          />
          <p className={styles.text}>
            By gamifying the crypto market we create exciting financial games where
            players test their skills to earn real money prizes.
          </p>
        </div>
        <img src={whatWeDoImg} alt="What we do?" />
      </div>
    </section>
  )
}
