import gameService from '@api/services/game.service'
import { WebsocketContext } from '@api/websocket-provider'
import LogoImg from '@assets/icons/logo-horizontal.png'
import Loader from '@components/shared/loader/loader.component'
import { useAppDispatch, useAppSelector } from '@hooks/use-app-dispatch.hook'
import { useGetBalance } from '@hooks/use-get-balance.hook'
import type { IGame } from '@models/game.interface'
import { GameStateEnum } from '@models/game.interface'
import { WsResponseAction } from '@models/ws.interface'
import { clearPicked } from '@store/currency-pick/currency-pick.slice'
import { resetGame, selectGame, setGame } from '@store/game/game.slice'
import { selectUser, setBalance } from '@store/user/user.slice'
import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import CurrencyPicker from './components/CurrencyPicker/currency-picker.component'
import Drafts from './components/Drafts/drafts.component'
import GameLengthTitle from './components/GameLengthTitle/game-length-title.component'
import Graph from './components/Graph/graph.component'
import Music from './components/Music/music.component'
import PrizePool from './components/PrizePool/prize-pool.component'
import ResultModal from './components/ResultModal/result-modal.component'
import Timer from './components/Timer/timer.component'
import styles from './game.module.scss'

export default function GamePage() {
  const { state } = useAppSelector(selectGame)
  const { id, balance } = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [expiryTime, setExpiryTime] = useState(new Date().toString())
  const [showResultModal, setShowResultModal] = useState(false)
  const [searchParameters] = useSearchParams()
  const { socket } = useContext(WebsocketContext)
  const { getBalance } = useGetBalance()
  const [timeOffset, setTimeOffset] = useState(0)
  const [musicPlaying, setMusicPlaying] = useState(false)

  const confirmResultAndRedirect = () => {
    setShowResultModal(false)
    navigate('/search-game')
    dispatch(clearPicked())
    dispatch(resetGame())
  }

  // Get game info from startup
  useEffect(() => {
    const requestGameInfo = async () => {
      setIsLoading(true)
      try {
        const serverTimestamp = await gameService.getServerTimestamp()
        const offset = Date.now() - serverTimestamp.data
        setTimeOffset(offset)
        const queryId = searchParameters.get('id') as string
        const gameInfo = await gameService.getGameInfo(queryId)
        dispatch(setGame(gameInfo.data))
        setIsLoading(false)
        if (state === GameStateEnum.preparation) {
          dispatch(clearPicked())
          getBalance()
          const startsAt = new Date(gameInfo.data.startsAt)
          const gameStartDate = new Date(startsAt.getTime() + offset + 5000).toISOString()
          setExpiryTime(gameStartDate)
          const secondsToStart = new Date(gameStartDate).getTime() - Date.now()
          setTimeout(() => {
            setIsLoading(true)
          }, secondsToStart)
        } else {
          const endsAt = new Date(gameInfo.data.endsAt)
          setExpiryTime(new Date(endsAt.getTime() + offset).toISOString())
          setMusicPlaying(true)
          // getBalance()
          // dispatch(setBalance(balance - gameInfo.data.bet))
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (id && state !== GameStateEnum.ended) {
      requestGameInfo()
    }
  }, [dispatch, searchParameters, id, state])

  // Websocket listeners
  useEffect(() => {
    if (!socket) {
      return
    }

    const startGameListener = (data: IGame) => {
      // console.log(data)
      dispatch(setGame(data))
      const endsAt = new Date(data.endsAt)
      setExpiryTime(new Date(endsAt.getTime() + timeOffset).toISOString())
      dispatch(setBalance(balance - data.bet))
      setMusicPlaying(true)
    }
    socket.on(WsResponseAction.gameStarted, startGameListener)

    const endGameListener = (data: IGame) => {
      dispatch(setGame(data))
      setShowResultModal(true)
      setMusicPlaying(false)
      // getBalance()
    }
    socket.on(WsResponseAction.gameEnds, endGameListener)

    const cancelGameListener = (data: any) => {
      alert(data)
      navigate('/search-game')
      dispatch(clearPicked())
      dispatch(resetGame())
    }
    socket.on(WsResponseAction.gameCanceled, cancelGameListener)

    return () => {
      socket.off(WsResponseAction.gameStarted, startGameListener)
      socket.off(WsResponseAction.gameEnds, endGameListener)
      socket.off(WsResponseAction.gameCanceled, cancelGameListener)
    }
  }, [dispatch, socket])

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader color="#02959F" />
        <p>Game is loading...</p>
      </div>
    )
  }

  return (
    <main className={`container ${styles.game}`}>
      <img src={LogoImg} alt="logo" className={styles.logo} />
      <div className={clsx(styles.withMusicWrapper, styles.withMusicWrapper_showMobile)}>
        <h1 className={styles.mobileTitle}>Lobby page</h1>
        <Music
          playing={musicPlaying}
          toggleAudio={() => setMusicPlaying(!musicPlaying)}
          hidden={state !== GameStateEnum.started}
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.mobileBlock}>
          <div className={styles.mobileRow}>
            <GameLengthTitle />
            <Timer expiry={expiryTime} />
          </div>
          <Drafts />
        </div>

        <div className={styles.styledBlock}>
          <div className={styles.withMusicWrapper}>
            <GameLengthTitle />
            <Music
              playing={musicPlaying}
              toggleAudio={() => setMusicPlaying(!musicPlaying)}
              hidden={state !== GameStateEnum.started}
            />
          </div>
          {state === GameStateEnum.preparation ? <CurrencyPicker /> : <Graph />}
        </div>
        <div className={styles.rightSide}>
          <div className={styles.styledBlock}>
            <div className={styles.prizeWrapper}>
              <PrizePool />
              <Timer expiry={expiryTime} />
            </div>
          </div>
          <div className={clsx(styles.styledBlock, styles.styledBlock_padSmall)}>
            <Drafts />
          </div>
        </div>
      </div>

      <ResultModal isOpen={showResultModal} onClose={() => confirmResultAndRedirect()} />
    </main>
  )
}
