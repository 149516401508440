import TokenChange from '@components/TokenChange/token-change.component'
import { useAppDispatch, useAppSelector } from '@hooks/use-app-dispatch.hook'
import type { ICurrency } from '@models/currency.interface'
import { GameStateEnum } from '@models/game.interface'
import { removePickedCurrency } from '@store/currency-pick/currency-pick.slice'
import { selectGame } from '@store/game/game.slice'
import clsx from 'clsx'
import React from 'react'

import styles from './chosen-token.module.scss'

interface IChosenTokenProperties {
  token: ICurrency | null
  hidden?: boolean
}

export default function ChosenToken(props: IChosenTokenProperties) {
  const { token, hidden } = props
  const { state } = useAppSelector(selectGame)
  const dispatch = useAppDispatch()

  return (
    <div className={styles.itemWrapper} title={token?.name}>
      <article
        className={clsx(
          styles.item,
          token === null && styles.item_empty,
          hidden && styles.item_hidden,
          state !== GameStateEnum.preparation && token && token.change >= 0 && styles.item_positive,
          state !== GameStateEnum.preparation && token && token.change < 0 && styles.item_negative
        )}
      >
        {token && (
          <>
            <button
              className={styles.closeBtn}
              onClick={() => dispatch(removePickedCurrency(token))}
            >
              &times;
            </button>
            <img src={`https://www.cryptocompare.com${token.image}`} alt="" />
          </>
        )}
      </article>

      {state !== GameStateEnum.preparation && (
        <div className={styles.itemChange}>
          <TokenChange value={token?.change as number} showIcon={false} />
        </div>
      )}
    </div>
  )
}
