import authService from '@api/services/auth.service'
import { GameStateEnum } from '@models/game.interface'
import { setLoggedIn, setLoggedOut, setUser, setWallet } from '@store/user/user.slice'
import { useEthers } from '@usedapp/core'
import { checkAndChangeChainId } from '@utils/utils'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { useAppDispatch } from './use-app-dispatch.hook'
import { useGetBalance } from './use-get-balance.hook'

export const useLogin = () => {
  const { activateBrowserWallet, account, deactivate, library } = useEthers()
  const dispatch = useAppDispatch()
  const { getBalance } = useGetBalance()
  const navigate = useNavigate()

  const createSignature = async () => {
    if (!library || !account) {
      return
    }
    try {
      // !!! Date + 1 day, change it later
      const expirationDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
      const message = `Tradasy Login\nAddress: ${account}\nExpiration: ${expirationDate}`
      // @ts-ignore
      const signer = library.getSigner()
      const signature = await signer.signMessage(message)
      const address = await signer.getAddress()
      return {
        message,
        signature,
        address,
      }
    } catch (error) {
      console.log(error)
      return { error }
    }
  }

  const getUserInfo = async () => {
    try {
      const userInfo = await authService.getUserInfo()
      dispatch(setUser(userInfo.data))
      dispatch(setWallet(account as string))
      getBalance()

      const lastGameId = userInfo.data.gameId?.toString()
      const gameInProgress = userInfo.data.gameState === GameStateEnum.started
      if (lastGameId && gameInProgress) {
        navigate({ pathname: '/game', search: createSearchParams({ id: lastGameId }).toString() })
      } else {
        navigate('/search-game')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const executeLogin = async () => {
    if (!window.ethereum) {
      // eslint-disable-next-line no-alert
      alert('Please install the Metamask extension.')
      return
    }

    try {
      await checkAndChangeChainId().then(async () => {
        await activateBrowserWallet()
      })
      const signResult = await createSignature()
      if (!signResult || signResult?.error) {
        return
      }
      // @ts-ignore
      const response = await authService.logIn(signResult?.message, signResult?.signature)
      const userTokens = JSON.stringify(response.data)
      localStorage.setItem('trokens', userTokens)
      dispatch(setLoggedIn())
    } catch (error) {
      console.log(error)
    }
  }

  const refreshTokens = async () => {
    const { refreshToken } = JSON.parse(localStorage.getItem('trokens') as string)
    try {
      await checkAndChangeChainId().then(async () => {
        await activateBrowserWallet()
      })
      const response = await authService.refreshTokens(refreshToken)
      const userTokens = JSON.stringify(response.data)
      localStorage.setItem('trokens', userTokens)
      dispatch(setLoggedIn())
    } catch (error) {
      console.log(error)
      localStorage.removeItem('trokens')
    }
  }

  const logout = async () => {
    await authService.logout()
    dispatch(setLoggedOut())
    deactivate()
    localStorage.removeItem('trokens')
  }

  return { executeLogin, logout, refreshTokens, getUserInfo }
}
