import React from 'react'
import { useTimer } from 'react-timer-hook'

import styles from './timer.module.scss'

interface ITimerProperties {
  expiry: any
  onExpire?: () => void
  // requestRestart: (newTime: any) => void
}

export default function Timer({ expiry, onExpire }: ITimerProperties) {
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: new Date(expiry),
    onExpire: () => restart(new Date(expiry)),
  })

  // useEffect(() => {
  //   if (expiry) {
  //     restart(expiry)
  //   }
  // }, [expiry, restart])

  return (
    <div className={styles.timer}>
      {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
    </div>
  )
}
