export enum BetEnum {
  _0 = 0,
  _1 = 1,
  _2 = 2,
  _5 = 5,
  _10 = 10,
}

export enum GameStateEnum {
  preparation = 0,
  started = 1,
  ended = 2,
  canceled = 3,
}

export enum DurationEnum {
  _10min = 0,
  _30min = 1,
  _60min = 2,
}

export enum ConnectionStateEnum {
  none = 0,
  search = 1,
  game = 2,
}

export interface IGame {
  id: number | null
  cryptos: ICryptos[]
  bet: BetEnum
  duration: DurationEnum
  state: GameStateEnum
  startsAt: string
  endsAt: string
  maxCryptos: number
  winner: IPlayer | null
}

export interface IPlayer {
  id: number
  username: string
}

interface ICryptos {
  user: {
    id: number
    username: string
  }
  cryptos: [
    {
      image: string
      name: string
      symbol: string
    }
  ]
}
