// import { lazy } from 'react'
import GamePage from '@pages/game/index.page'
import HomePage from '@pages/home/index.page'
import SearchPage from '@pages/search/index.page'

interface IRoutesProperties {
  path: string
  component: () => JSX.Element
  exact?: boolean
  requireAuth: boolean
}

const ROUTES: IRoutesProperties[] = [
  {
    path: '/',
    component: HomePage,
    exact: true,
    requireAuth: false,
  },
  { path: '/search-game', component: SearchPage, requireAuth: true },
  { path: '/game', component: GamePage, requireAuth: true },
]

// //If react suspense using
// const LAZY_ROUTES = [
//   {
//     path: "/",
//     component: lazy(() => import("../../pages/home/home.page")),
//     exact: true,
//   },
// ];

export default ROUTES
