import clsx from 'clsx'
import type { ReactNode } from 'react'
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

import styles from './modal-wrapper.module.scss'

export interface IModalWrapperProperties {
  className?: string
  children: ReactNode
  isOpen: boolean
  toggle: () => void
  styleVariant?: 'primary' | 'secondary'
}

const ModalWrapper = (props: IModalWrapperProperties) => {
  const { isOpen, toggle, className, children, styleVariant = 'primary' } = props

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
  }, [isOpen])

  return isOpen
    ? createPortal(
        <div className={styles.modal} onClick={toggle}>
          <div
            className={clsx(
              styles.modalContent,
              className,
              styleVariant === 'secondary' && styles.modalContent_secondary
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <>{children}</>
          </div>
        </div>,
        // @ts-expect-error
        document.querySelector('#portal')
      )
    : null
}

const Header: React.FC<any> = ({ children, style, ...rest }) => {
  return <div>{children}</div>
}

const Body: React.FC<any> = ({ children, style, ...rest }) => {
  return <div>{children}</div>
}

const Footer: React.FC<any> = ({ children, style, ...rest }) => {
  return <div>{children}</div>
}

ModalWrapper.Header = Header
ModalWrapper.Body = Body
ModalWrapper.Footer = Footer

export default ModalWrapper
