import Loader from '@components/shared/loader/loader.component'
import React from 'react'

import styles from './modals.module.scss'

export default function TransactionLoader() {
  return (
    <div className={styles.loader}>
      <h4 className={styles.title}>Waiting for your transaction</h4>
      <Loader color="#02959F" />
    </div>
  )
}
