import logoImg from '@assets/icons/logo-horizontal.png'
// import { ReactComponent as ProfileIcon } from '@assets/icons/profile-icon.svg'
import ProfileIcon from '@assets/icons/profile-icon.png'
import { ReactComponent as RefreshIcon } from '@assets/icons/refresh.svg'
import { ReactComponent as ThreeDotsIcon } from '@assets/icons/three-dots.svg'
import styles from '@components/shared/header/header.module.scss'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { useGetBalance } from '@hooks/use-get-balance.hook'
import { useLogin } from '@hooks/use-login.hook'
import { selectIsLoggedIn, selectUser } from '@store/user/user.slice'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import Button from '../button/button.component'
import Footer from '../footer/footer.component'
import DepositModal from '../modals/deposit-modal.component'
import NicknameModal from '../modals/nickname-modal.component'
import WithdrawModal from '../modals/withdraw-modal.component'
import ProfileMenu from '../profile-menu/profile-menu.component'

export default function Header(): JSX.Element {
  const { balance, username } = useAppSelector(selectUser)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const { executeLogin, logout } = useLogin()
  const [showPopover, setShowPopover] = useState(false)
  const popoverReference = useRef(null)
  const popoverButtonReference = useRef(null)
  const [showNicknameModal, setShowNicknameModal] = useState(false)
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [refreshButtonActive, setRefreshButtonActive] = useState(true)
  const { getBalance } = useGetBalance()

  const closePopover = () => {
    setShowPopover(false)
  }

  const openDepositModal = () => {
    setShowDepositModal(true)
    closePopover()
  }

  const openWithdrawModal = () => {
    setShowWithdrawModal(true)
    closePopover()
  }

  const handleLogout = () => {
    logout()
    closePopover()
  }

  const handleLogin = async () => {
    await executeLogin()
  }

  const requestBalance = () => {
    if (refreshButtonActive === false) {
      return
    }
    getBalance()
    setRefreshButtonActive(false)

    setTimeout(() => {
      setRefreshButtonActive(true)
    }, 10_000)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        popoverReference.current &&
        // @ts-ignore
        !popoverReference.current.contains(event.target) &&
        // @ts-ignore
        !popoverButtonReference.current.contains(event.target)
      ) {
        closePopover()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn && username === null) {
      setShowNicknameModal(true)
    } else {
      setShowNicknameModal(false)
    }
  }, [isLoggedIn, username])

  return (
    <header className={styles.header}>
      <a href="/" className={styles.logo}>
        <img src={logoImg} alt="Tradasy" />
      </a>
      {isLoggedIn ? (
        <div className={clsx(styles.user, showPopover && styles.user_active)}>
          <button
            className={styles.userBtn}
            onClick={() => setShowPopover(!showPopover)}
            ref={popoverButtonReference}
          >
            <img src={ProfileIcon} alt="profile" />
            <span>{username}</span>
          </button>

          <div className={styles.userDivider}>
            <ThreeDotsIcon />
          </div>

          <span>{balance} USDT</span>
          <button
            className={styles.refreshBtn}
            onClick={requestBalance}
            disabled={!refreshButtonActive}
          >
            <RefreshIcon />
          </button>
          <Button size="small" onClick={openDepositModal} additionalClassnames={styles.depositBtn}>
            Deposit
          </Button>

          <div className={styles.popover} ref={popoverReference}>
            <div className={styles.popover__mobileHeader}>
              <img src={logoImg} alt="Tradasy" />
              <button className={styles.popover__mobileClose} onClick={closePopover}>
                &times;
              </button>
            </div>
            <ProfileMenu
              openDepositModal={openDepositModal}
              openWithdrawModal={openWithdrawModal}
              handleLogout={handleLogout}
            />
            <div className={styles.popover__mobileFooter}>
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        <Button onClick={handleLogin}>Log in</Button>
      )}

      <NicknameModal isOpen={showNicknameModal} onClose={() => setShowNicknameModal(false)} />
      <DepositModal isOpen={showDepositModal} onClose={() => setShowDepositModal(false)} />
      <WithdrawModal isOpen={showWithdrawModal} onClose={() => setShowWithdrawModal(false)} />
    </header>
  )
}
