import { getRequest } from '@api/axios-client'
import type { ICurrencyHistory } from '@api/response.interface'
import type { ICurrency } from '@models/currency.interface'
import type { IGame } from '@models/game.interface'

class GameService {
  async getGameInfo(gameId: string) {
    return getRequest<IGame>(`game/${gameId}`)
  }

  async getCryptosList() {
    return getRequest<ICurrency[]>('crypto/all')
  }

  async getCryptoHistory(requestedTokens: string) {
    const requestedTime = new Date(Date.now() - 5 * 60_000)
    return getRequest<ICurrencyHistory>(
      `crypto/story/${requestedTokens}?start=${requestedTime.toISOString()}`
    )
  }

  async getGameHistory(gameId: number) {
    return getRequest(`crypto/story/game/${gameId}`)
  }

  async getServerTimestamp() {
    return getRequest<number>('sync/time')
  }
}

export default new GameService()
