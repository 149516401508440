import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import { DurationEnum } from '@models/game.interface'
import { selectGame } from '@store/game/game.slice'
import React from 'react'

import styles from './game-length-title.module.scss'

export default function GameLengthTitle() {
  const { duration } = useAppSelector(selectGame)

  const durationEnumToTime = (dur: DurationEnum) => {
    switch (dur) {
      case DurationEnum._10min:
        return 10
      case DurationEnum._30min:
        return 30
      case DurationEnum._60min:
        return 60
      default:
        return 0
    }
  }

  return <h3 className={styles.title}>{durationEnumToTime(duration)} minute game</h3>
}
