import { deleteRequest, getRequest, postRequest } from '@api/axios-client'
import type { IAccessTokens, IMeResponse } from '@api/response.interface'

class AuthService {
  async logIn(message: string, signature: string) {
    return postRequest<IAccessTokens>('auth/log-in', { message, signature })
  }

  async refreshTokens(refreshToken: string) {
    return postRequest<IAccessTokens>('auth/refresh-tokens', { value: refreshToken })
  }

  async logout() {
    return deleteRequest('auth/log-out')
  }

  async setMyUsername(username: string) {
    return postRequest('auth/me/username', { value: username })
  }

  async getUserInfo() {
    return getRequest<IMeResponse>('auth/me')
  }
}

export default new AuthService()
