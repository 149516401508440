import { combineReducers } from '@reduxjs/toolkit'
import currencyPickReducer from '@store/currency-pick/currency-pick.slice'
import gameReducer from '@store/game/game.slice'
import userReducer from '@store/user/user.slice'

// Include all the reducer to combine and provide to configure store.
const rootReducer = combineReducers({
  userReducer,
  currencyPickReducer,
  gameReducer,
})

export default rootReducer
