import { WebsocketContext } from '@api/websocket-provider'
import searchGameLogo from '@assets/icons/search-game-logo.png'
import Loader from '@components/shared/loader/loader.component'
import type { IGameVariant } from '@constants/game-variants.constant'
import { durationToEnum, gameVariants } from '@constants/game-variants.constant'
import { useAppDispatch, useAppSelector } from '@hooks/use-app-dispatch.hook'
import type { BetEnum } from '@models/game.interface'
import { WsAction, WsResponseAction } from '@models/ws.interface'
import { setGameId } from '@store/game/game.slice'
import { selectUser } from '@store/user/user.slice'
import React, { useContext, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import SearchCard from './components/SearchCard/search-card.component'
import styles from './search.module.scss'

export default function SearchPage() {
  const [selectedGame, setSelectedGame] = useState<IGameVariant | null>(null)
  const [isSearchStarted, setISearchStarted] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const { socket } = useContext(WebsocketContext)
  const dispatch = useAppDispatch()
  const { balance } = useAppSelector(selectUser)

  const startSearching = (searchingGame: IGameVariant, bet: BetEnum) => {
    setSelectedGame(searchingGame)
    if (balance < bet) {
      setError('Not enough USDT')
      return
    }
    setISearchStarted(true)
    setError('')

    const searchParameters = {
      duration: durationToEnum.get(searchingGame.duration),
      bet,
    }
    socket.emit(WsAction.startSearch, searchParameters)
  }

  const cancelSearching = () => {
    socket.emit(WsAction.stopSearch)
    setSelectedGame(null)
    setISearchStarted(false)
  }

  useEffect(() => {
    if (socket === null) {
      return
    }
    const searchStoppedListener = (data: string) => {
      console.log(data)
      setISearchStarted(false)
      setError(data)
    }
    socket.on(WsResponseAction.searchStopped, searchStoppedListener)

    const gameReadyListener = (data: number) => {
      dispatch(setGameId(data))
      navigate({
        pathname: '/game',
        search: createSearchParams({ id: data.toString() }).toString(),
      })
    }
    socket.on(WsResponseAction.gameReady, gameReadyListener)
  }, [dispatch, navigate, socket])

  if (!socket) {
    return (
      <div className={styles.loader}>
        <Loader color="#02959f" />
        <p>Establishing a connection...</p>
      </div>
    )
  }

  return (
    <main className={styles.search}>
      <img className={styles.logo} src={searchGameLogo} alt="Search Game" />
      <div className={styles.cardsWrapper}>
        {gameVariants.map((card) => (
          <SearchCard
            key={card.id}
            card={card}
            selectedGame={selectedGame}
            isSearchStarted={isSearchStarted}
            startSearching={startSearching}
            cancelSearching={cancelSearching}
            error={error}
          />
        ))}
      </div>

      <div className={styles.mobileSlider}>
        <h3>Choose game duration</h3>
        <Swiper
          slidesPerView={1}
          modules={[Pagination]}
          pagination={{
            clickable: true,
            renderBullet(index, className) {
              return `<span class="${className}">${gameVariants[index]?.duration}</span>`
            },
            bulletClass: styles.mobileBullet,
            bulletActiveClass: styles.mobileBullet_active,
          }}
          noSwiping={true}
          noSwipingClass="rc-slider"
        >
          {gameVariants.map((card) => (
            <SwiperSlide key={card.id}>
              <SearchCard
                key={card.id}
                card={card}
                selectedGame={selectedGame}
                isSearchStarted={isSearchStarted}
                startSearching={startSearching}
                cancelSearching={cancelSearching}
                error={error}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </main>
  )
}
