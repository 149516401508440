import Button from '@components/shared/button/button.component'
import Loader from '@components/shared/loader/loader.component'
import type { IGameVariant } from '@constants/game-variants.constant'
import { betNumberToEnum } from '@constants/game-variants.constant'
import clsx from 'clsx'
import Slider from 'rc-slider'
import React, { useState } from 'react'

import styles from './search-card.module.scss'

interface ISearchCardProperties {
  card: IGameVariant
  selectedGame: IGameVariant | null
  isSearchStarted: boolean
  startSearching: (card: IGameVariant, bet: number) => void
  cancelSearching: () => void
  error: string
}

export default function SearchCard(props: ISearchCardProperties) {
  const { card, selectedGame, isSearchStarted, startSearching, cancelSearching, error } = props
  const [buyInState, setBuyInState] = useState(card.betNumber)

  const handleStartSearch = () => {
    startSearching(card, betNumberToEnum.get(buyInState) as number)
  }

  return (
    <article
      className={clsx(
        styles.card,
        isSearchStarted && card !== selectedGame && styles.card_disabled
      )}
    >
      <div>
        <h3 className={styles.duration}>
          <span>{card.duration}</span> minutes
        </h3>
        <p className={styles.descr}>
          Choose <span>{card.maxCryptos}</span> cryptocurrencies
        </p>
      </div>
      {isSearchStarted && card === selectedGame ? (
        <div className={styles.loaderWrapper}>
          <Loader />
          Searching for opponent
        </div>
      ) : (
        <div className={styles.rangeWrapper}>
          <p>Select buy-in</p>
          <div className={styles.rangeMarks}>
            <span onClick={() => setBuyInState(1)}>0</span>
            <span onClick={() => setBuyInState(2)}>1</span>
            <span onClick={() => setBuyInState(3)}>2</span>
            <span onClick={() => setBuyInState(4)}>5</span>
            <span onClick={() => setBuyInState(5)}>10</span>
          </div>
          <Slider
            min={1}
            max={5}
            className={styles.rangeInput}
            value={buyInState}
            // @ts-ignore
            onChange={(value: number) => setBuyInState(value)}
          />
          <span>USDT</span>
        </div>
      )}

      {isSearchStarted && card === selectedGame ? (
        <Button additionalClassnames={styles.btn} onClick={cancelSearching}>
          Cancel
        </Button>
      ) : (
        <Button additionalClassnames={styles.btn} onClick={handleStartSearch}>
          Play
        </Button>
      )}

      {card === selectedGame && error && <small className={styles.error}>{error}</small>}
    </article>
  )
}
