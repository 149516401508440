// import { AuthGuard } from '@common/'
import { WebsocketProvider } from '@api/websocket-provider'
import ROUTES from '@constants/routes.constant'
import { useAppSelector } from '@hooks/use-app-dispatch.hook'
import MainLayout from '@layouts/main.layout'
import ErrorPage from '@pages/404/index.page'
import { selectIsLoggedIn } from '@store/user/user.slice'
import { Navigate, Route, Routes } from 'react-router-dom'

function AuthGuard({ children }: any) {
  const isLogged = useAppSelector(selectIsLoggedIn)
  return isLogged ? children : <Navigate to="/" replace />
}

export default function AppRouter(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {ROUTES.map(({ path, component: Component, requireAuth }, index) => {
          return (
            <Route
              path={path}
              key={`${path}${index}`}
              element={
                requireAuth ? (
                  <AuthGuard>
                    <WebsocketProvider>
                      <Component />
                    </WebsocketProvider>
                  </AuthGuard>
                ) : (
                  <Component />
                )
              }
            />
          )
        })}

        {/* <React.Suspense fallback={<div>Loading...</div>}>
        {ROUTES.map(({ Component, path, exact }, index) => (
          <Route path={path} key={path + index} element={<Component />} />
        ))}
      </React.Suspense> */}

        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  )
}
